<template>
  <div>
    <div
      v-if="savedCatalogCombo && !editingProduct && !showDetails"
      class="flex flex-col justify-center h-screen pb-safe bg-n-800 relative pb-safe"
    >
      <top-bar show-close @close="$emit('close')">
        <template #left>
          <div class="font-heading font-bold text-xl">
            {{ savedCatalogCombo.name }}
          </div>
        </template>
      </top-bar>

      <div class="px-4 mt-5 flex-1 flex flex-col gap-4">
        <div class="flex justify-between items-center w-full">
          <div class="font-heading text-n-0 text-base font-medium">
            {{ $filters.currency(savedCatalogCombo.price) }}
          </div>
          <l-button icon="plus" size="small" @click="addCombo"></l-button>
        </div>
      </div>

      <div
        class="w-full h-full pt-4 px-3 overflow-hidden flex flex-col"
        @click.stop
      >
        <div class="flex-1 flex flex-row w-full h-full">
          <div class="relative w-full flex flex-col overflow-hidden">
            <div
              v-if="savedCombos.length > 1"
              class="overflow-x-auto scrolling-touch mb-4"
            >
              <l-tabs
                :model-value="selectedComboIndex"
                :tabs="combos"
                type="primary"
                @update:model-value="selectComboIndex"
              />
            </div>

            <div class="overflow-x-auto scrolling-touch">
              <l-tabs
                v-model="selectedCategoryId"
                :tabs="categories"
                type="primary"
              />
            </div>

            <div class="text-sm text-n-0 my-4">
              <span v-if="categoryMin(selectedCategoryIndex) > 0">
                {{
                  $t('combo-editor.select-min-max-products', {
                    min: categoryMin(selectedCategoryIndex),
                    max: categoryMax(selectedCategoryIndex)
                  })
                }}
              </span>
              <span v-else class="text-sm text-n-0">
                {{
                  $t('combo-editor.select-max-of-products', {
                    max: categoryMax(selectedCategoryIndex)
                  })
                }}
              </span>
            </div>
            <div class="flex-1 min-h-0 overflow-y-scroll scrolling-touch">
              <div class="grid grid-auto-fill gap-2 relative">
                <menu-product
                  v-for="product in filteredProducts"
                  :key="product.id"
                  :product="product"
                  class="w-full sm:w-auto"
                  component-style="square"
                  :disabled="
                    categoryIsMaxed(selectedComboIndex, selectedCategoryIndex)
                  "
                  :selected-quantity="
                    productTotalSelected(selectedComboIndex, product.id)
                  "
                  @selected="saveProduct(product)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col items-stretch gap-3 p-4">
        <l-button
          :disabled="!allCategoriesReachesMin(selectedComboIndex)"
          @click="save()"
        >
          {{ $t('ordering.confirm') }}
        </l-button>
        <l-button type="secondary" @click="showDetails = true">
          {{ $t('combo-editor.see-combo-detail') }}
        </l-button>
      </div>
    </div>
    <div
      v-if="editingProduct || showDetails"
      class="fixed inset-0 h-full w-full"
    >
      <product-editor
        v-if="editingProduct"
        emit-close
        emit-save
        :product="editingProduct"
        :tab-id="tabId"
        :show-quantity="false"
        :selected-seat="selectedSeat"
        @close="editingProduct = null"
        @save-product="saveEditedProduct"
      />
      <combo-details
        v-else
        :map-category-selected-products="mapCategorySelectedProducts"
        :map-category-maxed="mapCategoryMaxed"
        :saved-catalog-combo="savedCatalogCombo"
        :saved-combos="savedCombos"
        @update-product-quantity="updateProductQuantity"
        @delete-product="deleteProduct"
        @edit-product="editProduct"
        @on-remove="onRemove"
        @close="showDetails = false"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { LTabs, LButton } from '@last/core-ui/paprika'
import {
  defineProps,
  defineEmits,
  ref,
  nextTick,
  onMounted,
  computed,
  watch
} from 'vue'
import MenuProduct from '@/components/ordering/MenuProduct.vue'
import ProductEditor from './ProductEditor.vue'
import ComboDetails from './ComboDetails.vue'
import { v4 as uuid } from 'uuid'
import { useComboEditor } from '@/components/ordering/combos/useComboEditor'
import { CatalogCombo, Combo, Product } from '@/types'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import TopBar from '@/mobile/components/TopBar.vue'

type Props = {
  catalogCombo: CatalogCombo
  editingCombo: Combo | null
  comboProduct: Product | null
  tabId: string
  seat: number | null
}

const { catalogCombo, editingCombo, tabId, seat } = defineProps<Props>()

const emit = defineEmits(['close'])

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const comboWrapper = ref<HTMLElement | null>(null)
const comboAnchor = ref<HTMLElement[]>([])
const selectedComboIndex = ref(0)
const showDetails = ref(false)
const editingProduct = ref<Product | null>(null)

const selectedSeat = parseInt(route.query.seat as string)

const selectedCategoryId = ref('')
const categories = computed(() =>
  catalogCombo.categories.map(category => ({
    id: category.id,
    name: category.name
  }))
)

const combos = computed(() =>
  savedCombos.value.map((_, i) => ({
    id: i,
    name: t('combo-editor.combo') + ' ' + (i + 1)
  }))
)

watch(
  () => selectedCategoryId.value,
  () => {
    selectedCategoryIndex.value = catalogCombo.categories.findIndex(
      category => category.id === selectedCategoryId.value
    )
  }
)

const {
  selectedProducts,
  savedCatalogCombo,
  savedEditingCombo,
  savedCombos,
  filteredProducts,
  selectedCategoryIndex,
  deleteProduct,
  getCategory,
  updateProductQuantity,
  saveCombo,
  productTotalSelected,
  categorySelectedProducts,
  categoryMax,
  categoryMin,
  categoryIsMaxed,
  allCategoriesReachesMin,
  preselectSelectedProducts
} = useComboEditor(catalogCombo, tabId, editingCombo, seat)

const mapCategoryMaxed = computed(() => {
  if (!savedCatalogCombo.value) {
    return []
  }
  return savedCombos.value.map((_, comboIndex) => {
    return savedCatalogCombo.value!.categories.map((_, index) => {
      return categoryIsMaxed(comboIndex, index)
    })
  })
})

const mapCategorySelectedProducts = computed(() => {
  if (!savedCatalogCombo) {
    return []
  }
  return savedCombos.value.map((_, comboIndex) => {
    return savedCatalogCombo.value!.categories.map((_, index) => {
      return categorySelectedProducts(comboIndex, index)
    })
  })
})

function save() {
  saveCombo()
  emit('close')
}

onMounted(() => {
  if (!catalogCombo) {
    router.replace({
      name: 'orderManagement',
      params: { tabId: tabId }
    })
  }
  savedCatalogCombo.value = catalogCombo
  savedEditingCombo.value = editingCombo
  if (editingCombo) {
    savedCombos.value = [{ ...editingCombo }]
  } else {
    savedCombos.value = [{ ...catalogCombo }]
  }

  if (editingCombo) {
    preselectSelectedProducts()
  } else {
    preselectSelectedByDefault()
  }
  const querySeat = route.query.selectedSeat as string | undefined
  if (seat && querySeat && parseInt(querySeat) !== seat) {
    router.replace({
      query: { ...route.query, selectedSeat: seat }
    })
  }
})

function editProduct(comboIndex: number, product: Product) {
  selectedComboIndex.value = comboIndex
  editingProduct.value = product
}

function addCombo() {
  savedCombos.value.push({ ...catalogCombo })
  selectedComboIndex.value = 0
  while (
    selectedComboIndex.value < savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value)
  ) {
    selectedComboIndex.value++
  }
  goTo(selectedComboIndex.value)
  preselectSelectedByDefault()
}

function selectCategory(categoryIndex: number) {
  selectedCategoryId.value = categories.value[categoryIndex].id
  selectedComboIndex.value = 0
  while (
    selectedComboIndex.value < savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, categoryIndex)
  ) {
    selectedComboIndex.value++
  }
  goTo(selectedComboIndex.value)
}

function saveEditedProduct(product: Product) {
  selectedProducts.value = {
    ...selectedProducts.value,
    [selectedComboIndex.value]: {
      ...selectedProducts.value[selectedComboIndex.value],
      [product.id]: product
    }
  }
  editingProduct.value = null

  while (
    selectedComboIndex.value < savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value)
  ) {
    selectedComboIndex.value++
  }
  if (
    selectedComboIndex.value == savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value) &&
    selectedCategoryIndex.value !=
      savedCatalogCombo.value!.categories.length - 1
  ) {
    selectCategory(selectedCategoryIndex.value + 1)
  }
  goTo(selectedComboIndex.value)
}

function saveProduct(product: Product) {
  if (categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value)) {
    return
  }

  let comboProduct = {
    id: uuid(),
    name: product.name,
    parentProduct: product.id,
    priceImpact: product.priceImpact,
    modifiers: product.modifiers,
    modifierGroups: product.modifierGroups,
    comments: product.comments,
    quantity: 1,
    course: product.course || 'Main',
    categoryId: getCategory(selectedCategoryIndex.value)!.id
  }

  let mandatory = product.modifierGroups.some(
    group => group.min && group.min > 0
  )
  if (mandatory) {
    editingProduct.value = comboProduct
    return
  } else {
    editingProduct.value = null
  }

  if (!selectedProducts.value[selectedComboIndex.value]) {
    selectedProducts.value[selectedComboIndex.value] = {}
  }

  selectedProducts.value = {
    ...selectedProducts.value,
    [selectedComboIndex.value]: {
      ...selectedProducts.value[selectedComboIndex.value],
      [comboProduct.id]: comboProduct
    }
  }

  while (
    selectedComboIndex.value < savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value)
  ) {
    selectedComboIndex.value++
  }

  if (
    selectedComboIndex.value == savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value) &&
    selectedCategoryIndex.value !=
      savedCatalogCombo.value!.categories.length - 1
  ) {
    selectCategory(selectedCategoryIndex.value + 1)
  }
  goTo(selectedComboIndex.value)
}

function goTo(comboIndex: number) {
  nextTick(() => {
    let scrollWrapper = comboWrapper.value
    if (!scrollWrapper) return
    selectedComboIndex.value = comboIndex
    let target = comboAnchor.value[comboIndex].$el
    if (!target) return
    scrollWrapper.scrollTo({
      behavior: 'smooth',
      top: target.offsetTop - scrollWrapper.offsetTop
    })
  })
}

function selectComboIndex(comboIndex: number) {
  selectedComboIndex.value = comboIndex
  goTo(comboIndex)
}

function onRemove(comboIndex: number) {
  if (savedCombos.value.length == 1) emit('close')

  for (let i = comboIndex; i < savedCombos.value.length - 1; i++) {
    selectedProducts.value[i] = {
      ...selectedProducts.value[i + 1]
    }
  }

  delete selectedProducts.value[savedCombos.value.length - 1]
  savedCombos.value.splice(comboIndex, 1)

  selectedComboIndex.value = 0
  while (
    selectedComboIndex.value < savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value)
  ) {
    selectedComboIndex.value++
  }
  goTo(selectedComboIndex.value)
}

function preselectProduct(product: Product) {
  const comboIndex = savedCombos.value.length - 1
  let mandatory = product.modifierGroups.some(
    group => group.min && group.min > 0
  )
  if (mandatory) return

  let comboProduct = {
    id: uuid(),
    name: product.name,
    parentProduct: product.id,
    priceImpact: product.priceImpact,
    modifiers: product.modifiers,
    modifierGroups: product.modifierGroups,
    comments: product.comments,
    quantity: 1,
    course: product.course || 'Main',
    categoryId: product.categoryId
  }

  selectedProducts.value = {
    ...selectedProducts.value,
    [comboIndex]: {
      ...selectedProducts.value[comboIndex],
      [comboProduct.id]: comboProduct
    }
  }
}

function preselectSelectedByDefault() {
  catalogCombo.categories
    .filter(category => category.selectedByDefault)
    .flatMap(category => category.products)
    .forEach(categoryProduct => preselectProduct(categoryProduct))
}
</script>

<style scoped>
.grid-auto-fill {
  grid-template-columns: repeat(auto-fill, minmax(9.375rem, 1fr));
}
</style>
