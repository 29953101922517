<template>
  <div class="flex flex-col pt-3 gap-3 border-t border-n-600">
    <div class="flex flex-col px-3 gap-1 text-n-0 font-body text-sm leading-4">
      <div
        v-for="billDiscount in billsDiscounts"
        :key="billDiscount.amount"
        class="flex justify-between"
      >
        <div class="flex gap-1">
          <div>{{ $t('tabs.discount') }}</div>
          <div v-if="billDiscount.type === 'percentage'">
            {{ billDiscount.amount }}%
          </div>
        </div>
        <div>-{{ $filters.currency(billDiscount.discountTotal) }}</div>
      </div>
      <div v-if="minimumBasketSurcharge" class="flex justify-between">
        <div>
          {{ $t('tabs.minimum-basket-surcharge') }}
        </div>
        <div>
          {{ $filters.currency(minimumBasketSurcharge) }}
        </div>
      </div>
      <div
        v-if="deliveryFee.isFree || deliveryFee.value > 0"
        class="flex justify-between"
      >
        <div>{{ $t('ordering.delivery-fee') }}</div>
        <div v-if="deliveryFee.isFree">
          {{ $t('ordering.free-delivery') }}
        </div>
        <div v-else>{{ $filters.currency(deliveryFee.value) }}</div>
      </div>
      <div v-if="extraCharges" class="flex justify-between items-center">
        <div class="text-sm">{{ $t('tabs.extra-charges') }}</div>
        <div>
          {{ $filters.currency(extraCharges) }}
        </div>
      </div>
      <div
        class="flex items-center justify-between font-heading font-bold text-xl leading-5 pb-2"
      >
        <div>{{ $t('tabs.total') }}</div>
        <div class="flex items-center gap-2">
          <div
            v-if="total > 0"
            class="py-1 px-2 rounded-2xl text-xs font-body font-normal"
            :class="{ 'bg-r-300': !isFullyPaid, 'bg-g-500': isFullyPaid }"
          >
            <div v-if="isFullyPaid">{{ $t('checkout.paid') }}</div>
            <div v-else>{{ $t('checkout.to-pay') }}</div>
          </div>
          <div>{{ $filters.currency(total) }}</div>
        </div>
      </div>
    </div>
    <template v-if="showPending">
      <div v-if="size === 'large'" class="flex border-t border-b border-n-600">
        <div
          class="flex flex-col py-2 border-r border-n-600 items-center justify-center flex-1 text-g-500 text"
        >
          <div>{{ $t('checkout.paid') }}</div>
          <div class="font-heading font-medium text-base leading-4">
            {{ $filters.currency(totalPaid) }}
          </div>
        </div>
        <div
          class="flex flex-col py-2 items-center justify-center flex-1 text-r-300"
        >
          <div>{{ $t('checkout.pending') }}</div>
          <div class="font-heading font-medium text-base leading-4">
            {{ $filters.currency(totalPending) }}
          </div>
        </div>
      </div>
      <div
        v-else
        class="flex text-r-300 justify-between p-3 border-t border-b border-n-600 box-border font-body text-sm leading-4"
      >
        <div>{{ $t('checkout.pending') }}</div>
        <div>{{ $filters.currency(totalPending) }}</div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useTabs } from '@/composables/useTabs'
import { computed } from 'vue'

type Props = {
  tabId: string
  size: 'small' | 'large'
}
const props = withDefaults(defineProps<Props>(), {
  size: 'large'
})

const tabId = computed(() => props.tabId)

const {
  total,
  totalPaid,
  totalPending,
  deliveryFee,
  minimumBasketSurcharge,
  extraCharges,
  billsDiscounts,
  isFullyPaid
} = useTabs(tabId)

const showPending = computed(
  () => totalPaid.value > 0 && totalPending.value > 0
)
</script>
