<template>
  <div class="spinner">
    <div
      :class="[`spinner-icon--${size}`, `spinner-icon--${theme}`]"
      class="spinner-icon"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'small'
    },
    theme: {
      type: String,
      default: 'light'
    }
  }
}
</script>

<style scoped>
.spinner {
  animation: loading-bar-spinner 0.6s linear infinite;
}
.spinner .spinner-icon {
  border-radius: 50%;
}
.spinner .spinner-icon--light {
  border-top-color: #fff !important;
  border-left-color: #fff !important;
}
.spinner .spinner-icon--dark {
  border-top-color: #00c8b1 !important;
  border-left-color: #00c8b1 !important;
}
.spinner .spinner-icon--small {
  width: 20px;
  height: 20px;
  border: solid 2px transparent;
}
.spinner .spinner-icon--medium {
  width: 40px;
  height: 40px;
  border: solid 4px transparent;
}
@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
