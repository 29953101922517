<template>
  <div
    class="fixed top-0 left-0 h-full pb-safe w-screen z-30 overflow-hidden flex flex-col justify-center items-center backdrop-blur-sm"
    @click="$emit('close')"
  >
    <l-loading-spinner
      v-if="loading && !showCancelShipmentPopup"
      :size="'medium'"
    />
    <cancel-shipment-popup
      v-if="showCancelShipmentPopup"
      :courier="jobCourier"
      :tab-id="tab.id"
      @close="$emit('close')"
    />
    <completed-shipment-popup
      v-else-if="!loading && showCompletedShipmentPopup"
      @close="$emit('close')"
    />
    <cancelled-shipment-popup
      v-else-if="!loading && showCancelledShipmentPopup"
      :cancel-reason="job.cancelReason"
      @close="$emit('close')"
    />
    <map-modal v-else ref="mapModal" :job="job" :tab="tab" />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
import api from '@/api'
import MapModal from './MapModal.vue'
import CancelledShipmentPopup from './CancelledShipmentPopup.vue'
import CompletedShipmentPopup from './CompletedShipmentPopup.vue'
import CancelShipmentPopup from './CancelShipmentPopup.vue'
import LLoadingSpinner from '@last/core-ui/v3/components/LLoadingSpinner.vue'
import { Tab } from '@/types'

type Props = {
  tab: Tab
  cancel?: boolean
}
const props = withDefaults(defineProps<Props>(), {
  cancel: false
})

const pollingInterval = ref(null)
const job = ref(null)
const loading = ref(false)

const jobCourier = computed(() => job.value?.courier)

const showCancelShipmentPopup = computed(() => {
  return (
    props.cancel &&
    (!job.value || (!job.value.isDelivered && !job.value.isCancelled))
  )
})

const showCompletedShipmentPopup = computed(() => {
  return job.value && job.value.isDelivered
})

const showCancelledShipmentPopup = computed(() => {
  return job.value && job.value.isCancelled
})

async function getDeliveryInfo() {
  if (props.tab.deliveryOrder.shipment) {
    let response = await api.get(`/tabs/${props.tab.id}/shipment`)
    job.value = response.data !== '' ? response.data : null
  } else {
    job.value = {
      courier: {},
      deliveries: [
        {
          address: props.tab.deliveryOrder.address,
          latitude: props.tab.deliveryOrder.latitude,
          longitude: props.tab.deliveryOrder.longitude
        }
      ],
      shipmentPickedUp: true
    }
  }
}

async function getShipmentInfo() {
  // Implement the logic for getShipmentInfo if needed
}

onMounted(async () => {
  if (loading.value) return
  loading.value = true
  pollingInterval.value = setInterval(getShipmentInfo, 1000 * 3)
  await getDeliveryInfo()
  loading.value = false
})

onBeforeUnmount(() => {
  clearInterval(pollingInterval.value)
})
</script>
