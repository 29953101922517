<template>
  <product-editor
    v-if="editingProduct"
    :product="editingProduct"
    :show-course="true"
    :show-quantity="false"
    @save-product="saveEditedProduct"
    @close="editingProduct = null"
  />
  <div
    v-else
    class="absolute top-0 left-0 z-20 flex flex-col justify-start w-screen h-screen pb-safe p-5 overflow-y-scroll scrolling-touch bg-n-900/80 backdrop-blur-sm"
  >
    <div class="rounded-2xl overflow-hidden flex h-full bg-n-800">
      <div class="px-5 py-6 min-w-0 flex-1 flex flex-col" @click.stop>
        <div class="flex items-center justify-between text-xl text-n-0 mb-12">
          <div class="font-bold font-heading">
            {{ catalogCombo.name }}
          </div>
          <div class="font-text font-light">
            {{ $filters.currency(catalogCombo.price) }}
          </div>
        </div>

        <div v-horizontal-scroll class="overflow-x-auto scrolling-touch">
          <l-tabs
            v-model="selectedCategoryId"
            :tabs="categories"
            type="primary"
          />
        </div>
        <div class="flex flex-row text-sm text-n-0">
          <div class="flex-1 mt-4 mb-9">
            <span v-if="categoryMin(selectedCategoryIndex) > 0">
              {{
                $t('combo-editor.select-min-max-products', {
                  min: categoryMin(selectedCategoryIndex),
                  max: categoryMax(selectedCategoryIndex)
                })
              }}
            </span>
            <span v-else>
              {{
                $t('combo-editor.select-max-of-products', {
                  max: categoryMax(selectedCategoryIndex)
                })
              }}
            </span>
          </div>
          <div class="flex-none">
            <span
              :class="{
                'text-r-500':
                  totalSelectedProducts < categoryMin(selectedCategoryIndex),
                'text-g-500':
                  totalSelectedProducts >= categoryMin(selectedCategoryIndex)
              }"
              >{{ totalSelectedProducts }}</span
            >/{{ categoryMax(selectedCategoryIndex) }}
          </div>
        </div>
        <div class="flex-1 min-h-0 overflow-y-scroll scrolling-touch">
          <div class="grid grid-auto-fill gap-2 relative">
            <menu-product
              v-for="product in filteredProducts"
              :key="product.id"
              :product="product"
              class="w-full sm:w-auto"
              component-style="square"
              :disabled="
                categoryIsMaxed(selectedComboIndex, selectedCategoryIndex)
              "
              :selected-quantity="
                productTotalSelected(selectedComboIndex, product.id)
              "
              @selected="saveProduct(product)"
            />
          </div>
        </div>
      </div>

      <div
        class="w-80 shrink-0 flex flex-col border-l border-n-600 relative h-full"
      >
        <div class="h-12 flex justify-end items-center">
          <l-button
            size="small"
            icon="close"
            type="text"
            color="white"
            @click="$emit('close')"
          />
        </div>
        <div class="flex px-3 py-3 items-center justify-between">
          <div class="font-heading font-medium text-n-0">
            {{ $t('combo-editor.combos') }}
          </div>
          <l-button icon="plus" size="small" @click="addCombo"></l-button>
        </div>
        <div
          ref="comboWrapper"
          class="overflow-y-scroll scrolling-touch flex-1"
        >
          <div v-if="savedCombos.length > 1">
            <combo-list
              v-for="(_, comboIndex) in savedCombos"
              :key="comboIndex"
              ref="comboAnchors"
              :section-name="$t('combo-editor.combo') + ' ' + (comboIndex + 1)"
              :section-selected="selectedComboIndex === comboIndex"
              :show-remove="showRemoveButton(comboIndex)"
              @selected-section="selectComboIndex(comboIndex)"
              @on-remove="onRemove(comboIndex)"
            >
              <div
                v-for="(category, index) in catalogCombo.categories"
                :key="category.id"
              >
                <template
                  v-if="categorySelectedProductsQuantity(comboIndex, index) > 0"
                >
                  <div class="font-body px-4 py-2 text-n-200">
                    {{ category.name }}
                  </div>
                  <combo-product
                    v-for="product in categorySelectedProducts(
                      comboIndex,
                      index
                    )"
                    :key="product.id"
                    :product="product"
                    :quantity-is-maxed="categoryIsMaxed(comboIndex, index)"
                    @delete="deleteProduct(comboIndex, product.id)"
                    @update-quantity="
                      newQuantity =>
                        updateProductQuantity(
                          product.id,
                          newQuantity,
                          comboIndex,
                          index
                        )
                    "
                    @selected="editProduct(comboIndex, product)"
                  />
                </template>
              </div>
            </combo-list>
          </div>
          <div v-else>
            <div
              v-for="(category, index) in catalogCombo.categories"
              :key="category.id"
              ref="comboAnchors"
            >
              <template v-if="categorySelectedProductsQuantity(0, index) > 0">
                <div class="font-body px-4 py-2 text-n-200">
                  {{ category.name }}
                </div>
                <combo-product
                  v-for="product in categorySelectedProducts(0, index)"
                  :key="product.id"
                  :product="product"
                  :quantity-is-maxed="categoryIsMaxed(0, index)"
                  @delete="deleteProduct(0, product.id)"
                  @update-quantity="
                    newQuantity =>
                      updateProductQuantity(product.id, newQuantity, 0, index)
                  "
                  @selected="editProduct(0, product)"
                />
              </template>
            </div>
          </div>
        </div>
        <div class="py-5 px-6">
          <l-button
            :disabled="!allCategoriesReachesMin(selectedComboIndex)"
            class="w-full"
            @click="save"
          >
            {{
              savedEditingCombo
                ? $t('combo-editor.save')
                : $t('combo-editor.add')
            }}
          </l-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LTabs, LButton } from '@last/core-ui/paprika'
import {
  defineProps,
  defineEmits,
  ref,
  nextTick,
  onMounted,
  computed,
  watch
} from 'vue'
import MenuProduct from '../MenuProduct.vue'
import ComboProduct from './ComboProduct.vue'
import ProductEditor from '../modifiers/ProductEditor.vue'
import { v4 as uuid } from 'uuid'
import ComboList from './ComboList.vue'
import { useComboEditor } from './useComboEditor'
import { CatalogCombo, Combo, Product } from '@/types'

type Props = {
  catalogCombo: CatalogCombo
  editingCombo: Combo | null
  comboProduct?: Product
  tabId: string
  seat: number | null
}

const { catalogCombo, editingCombo, tabId, seat } = defineProps<Props>()

const emit = defineEmits(['close'])

const comboWrapper = ref<HTMLElement | null>(null)
const comboAnchors = ref<HTMLElement[]>([])
const selectedComboIndex = ref(0)
const editingProduct = ref<Product | null>(null)

const selectedCategoryId = ref('')
const categories = computed(() =>
  catalogCombo.categories.map(category => ({
    id: category.id,
    name: category.name
  }))
)

watch(
  () => selectedCategoryId.value,
  () => {
    selectedCategoryIndex.value = catalogCombo.categories.findIndex(
      category => category.id === selectedCategoryId.value
    )
  }
)

const {
  selectedProducts,
  savedCatalogCombo,
  savedEditingCombo,
  savedCombos,
  filteredProducts,
  selectedCategoryIndex,
  deleteProduct,
  getCategory,
  updateProductQuantity,
  saveCombo,
  productTotalSelected,
  categorySelectedProducts,
  categorySelectedProductsQuantity,
  categoryMax,
  categoryMin,
  categoryIsMaxed,
  allCategoriesReachesMin,
  preselectSelectedProducts
} = useComboEditor(catalogCombo, tabId, editingCombo, seat)

const totalSelectedProducts = computed(() =>
  categorySelectedProductsQuantity(
    selectedComboIndex.value,
    selectedCategoryIndex.value
  )
)

function save() {
  saveCombo()
  emit('close')
}

onMounted(() => {
  savedCatalogCombo.value = catalogCombo
  savedEditingCombo.value = editingCombo
  if (editingCombo) {
    savedCombos.value = [{ ...editingCombo }]
  } else {
    savedCombos.value = [{ ...catalogCombo }]
  }

  if (editingCombo) {
    preselectSelectedProducts()
  } else {
    preselectSelectedByDefault()
  }
})

function editProduct(comboIndex: number, product: Product) {
  selectedComboIndex.value = comboIndex
  editingProduct.value = product
}

function addCombo() {
  savedCombos.value.push({ ...catalogCombo })
  selectedComboIndex.value = 0
  while (
    selectedComboIndex.value < savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value)
  ) {
    selectedComboIndex.value++
  }
  goTo(selectedComboIndex.value)
  preselectSelectedByDefault()
}

function showRemoveButton(comboIndex: number) {
  return (
    !selectedProducts.value[comboIndex] ||
    Object.keys(selectedProducts.value[comboIndex]).length == 0
  )
}

function selectCategory(categoryIndex: number) {
  selectedCategoryId.value = categories.value[categoryIndex].id
  selectedComboIndex.value = 0
  while (
    selectedComboIndex.value < savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, categoryIndex)
  ) {
    selectedComboIndex.value++
  }
  goTo(selectedComboIndex.value)
}

function saveEditedProduct(product: Product) {
  selectedProducts.value = {
    ...selectedProducts.value,
    [selectedComboIndex.value]: {
      ...selectedProducts.value[selectedComboIndex.value],
      [product.id]: product
    }
  }
  editingProduct.value = null

  while (
    selectedComboIndex.value < savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value)
  ) {
    selectedComboIndex.value++
  }
  if (
    selectedComboIndex.value == savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value) &&
    selectedCategoryIndex.value !=
      savedCatalogCombo.value!.categories.length - 1
  ) {
    selectCategory(selectedCategoryIndex.value + 1)
  }
  goTo(selectedComboIndex.value)
}

function saveProduct(product: Product) {
  if (categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value)) {
    return
  }

  let comboProduct = {
    id: uuid(),
    name: product.name,
    parentProduct: product.id,
    priceImpact: product.priceImpact,
    modifiers: product.modifiers,
    modifierGroups: product.modifierGroups,
    comments: product.comments,
    quantity: 1,
    course: product.course || 'Main',
    categoryId: getCategory(selectedCategoryIndex.value)!.id
  }

  let mandatory = product.modifierGroups.some(
    group => group.min && group.min > 0
  )
  if (mandatory) {
    editingProduct.value = comboProduct as Product
    return
  } else {
    editingProduct.value = null
  }

  if (!selectedProducts.value[selectedComboIndex.value]) {
    selectedProducts.value[selectedComboIndex.value] = {}
  }

  selectedProducts.value = {
    ...selectedProducts.value,
    [selectedComboIndex.value]: {
      ...selectedProducts.value[selectedComboIndex.value],
      [comboProduct.id]: comboProduct as Product
    }
  }

  while (
    selectedComboIndex.value < savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value)
  ) {
    selectedComboIndex.value++
  }

  if (
    selectedComboIndex.value == savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value) &&
    selectedCategoryIndex.value !=
      savedCatalogCombo.value!.categories.length - 1
  ) {
    selectCategory(selectedCategoryIndex.value + 1)
  }
  goTo(selectedComboIndex.value)
}

function goTo(comboIndex: number) {
  nextTick(() => {
    let scrollWrapper = comboWrapper.value
    if (!scrollWrapper) return
    selectedComboIndex.value = comboIndex
    let target = comboAnchors.value[comboIndex]
    if (!target) return
    scrollWrapper.scrollTo({
      behavior: 'smooth',
      top: target.offsetTop + scrollWrapper.offsetTop
    })
  })
}

function selectComboIndex(comboIndex: number) {
  selectedComboIndex.value = comboIndex
  goTo(comboIndex)
}

function onRemove(comboIndex: number) {
  if (savedCombos.value.length == 1) emit('close')

  for (let i = comboIndex; i < savedCombos.value.length - 1; i++) {
    selectedProducts.value[i] = {
      ...selectedProducts.value[i + 1]
    }
  }

  delete selectedProducts.value[savedCombos.value.length - 1]
  savedCombos.value.splice(comboIndex, 1)

  selectedComboIndex.value = 0
  while (
    selectedComboIndex.value < savedCombos.value.length - 1 &&
    categoryIsMaxed(selectedComboIndex.value, selectedCategoryIndex.value)
  ) {
    selectedComboIndex.value++
  }
  goTo(selectedComboIndex.value)
}

function preselectProduct(product: Product) {
  const comboIndex = savedCombos.value.length - 1
  let mandatory = product.modifierGroups.some(
    group => group.min && group.min > 0
  )
  if (mandatory) return

  let comboProduct = {
    id: uuid(),
    name: product.name,
    parentProduct: product.id,
    priceImpact: product.priceImpact,
    modifiers: product.modifiers,
    modifierGroups: product.modifierGroups,
    comments: product.comments,
    quantity: 1,
    course: product.course || 'Main',
    categoryId: product.categoryId
  }

  selectedProducts.value = {
    ...selectedProducts.value,
    [comboIndex]: {
      ...selectedProducts.value[comboIndex],
      [comboProduct.id]: comboProduct as Product
    }
  }
}

function preselectSelectedByDefault() {
  catalogCombo.categories
    .filter(category => category.selectedByDefault)
    .flatMap(category => category.products)
    .forEach(categoryProduct => preselectProduct(categoryProduct))
}
</script>

<style scoped>
.grid-auto-fill {
  grid-template-columns: repeat(auto-fill, minmax(9.375rem, 1fr));
}
</style>
