import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import api from '@/api'
import { StockData, StockItem, StockItemTypes, StockStateFilter } from '@/types'
import { useAuthStore } from '@/store/auth'
import { useNotifications } from '@/composables/useNotifications'
import { useI18n } from 'vue-i18n'
import { useFuse } from '@vueuse/integrations/useFuse'

export const useStockStore = defineStore('stock', () => {
  const authStore = useAuthStore()
  const { locationId } = storeToRefs(authStore)
  const { notifyError } = useNotifications()
  const { t } = useI18n()

  const isLoading = ref<boolean>(true)

  const stockData = ref<StockData>({
    product: [],
    combo: [],
    modifier: [],
    modifierGroup: []
  })

  const searchQuery = ref<string>('')
  const activeStateFilter = ref<StockStateFilter>('ALL')
  const activeTypeFilter = ref<StockItemTypes | 'all'>('all')

  const fuseOptions = {
    keys: ['name'],
    threshold: 0.4,
    minMatchCharLength: 2
  }

  const productSearch = useFuse(searchQuery, () => stockData.value.product, {
    matchAllWhenSearchEmpty: true,
    fuseOptions
  })

  const comboSearch = useFuse(searchQuery, () => stockData.value.combo, {
    matchAllWhenSearchEmpty: true,
    fuseOptions
  })

  const modifierSearch = useFuse(searchQuery, () => stockData.value.modifier, {
    matchAllWhenSearchEmpty: true,
    fuseOptions
  })

  const modifierGroupSearch = useFuse(
    searchQuery,
    () => stockData.value.modifierGroup,
    {
      matchAllWhenSearchEmpty: true,
      fuseOptions
    }
  )

  const filteredStockData = computed<StockData>(() => {
    const filter = (item: StockItem) => {
      return (
        (activeStateFilter.value === 'ENABLED' && item.enabled) ||
        (activeStateFilter.value === 'DISABLED' && !item.enabled) ||
        activeStateFilter.value === 'ALL'
      )
    }

    return {
      product: productSearch.results.value
        .map(el => el.item)
        .filter(item => filter(item)),
      combo: comboSearch.results.value
        .map(el => el.item)
        .filter(item => filter(item)),
      modifier: modifierSearch.results.value
        .map(el => el.item)
        .filter(item => filter(item)),
      modifierGroup: modifierGroupSearch.results.value
        .map(el => el.item)
        .filter(item => filter(item))
    }
  })

  const hasResults = computed(() => {
    return (
      filteredStockData.value.product.length > 0 ||
      filteredStockData.value.combo.length > 0 ||
      filteredStockData.value.modifier.length > 0 ||
      filteredStockData.value.modifierGroup.length > 0
    )
  })

  async function fetchStock(): Promise<void> {
    if (
      stockData.value.product.length > 0 ||
      stockData.value.combo.length > 0 ||
      stockData.value.modifier.length > 0 ||
      stockData.value.modifierGroup.length > 0
    )
      return
    isLoading.value = true
    const { data } = await api.get('/menu-availability')

    const mapItem = (item: StockItem) => ({
      name: item.name,
      shortName: item.shortName,
      id: item.id,
      organizationId: item.organizationId,
      color: item.color,
      imageId: item.imageId,
      enabled: item.enabled || false
    })

    stockData.value.product = data.products.map((product: StockItem) => ({
      type: 'product',
      ...mapItem(product)
    }))
    stockData.value.combo = data.combos.map((combo: StockItem) => ({
      type: 'combo',
      ...mapItem(combo)
    }))
    stockData.value.modifier = data.modifiers.map((modifier: StockItem) => ({
      type: 'modifier',
      ...mapItem(modifier)
    }))
    stockData.value.modifierGroup = data.modifierGroups.map(
      (modifierGroup: StockItem) => ({
        type: 'modifierGroup',
        ...mapItem(modifierGroup)
      })
    )

    isLoading.value = false
  }

  async function toggleItem(item: StockItem, enabled: boolean): Promise<void> {
    const stockItemTypeToEndpoint = {
      product: 'products',
      combo: 'combos',
      modifier: 'modifiers',
      modifierGroup: 'modifier_groups'
    }

    try {
      item.enabled = enabled
      await api.put(
        `/locations/${locationId.value}/${stockItemTypeToEndpoint[item.type]}/${item.id}`,
        { enabled }
      )
    } catch (e) {
      item.enabled = !enabled
      notifyError({
        title: t('menu-availability.error-saving-changes')
      })
    }
  }

  return {
    isLoading,
    hasResults,
    stockData,
    searchQuery,
    activeStateFilter,
    activeTypeFilter,
    filteredStockData,
    fetchStock,
    toggleItem
  }
})
