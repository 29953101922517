<template>
  <div class="flex mt-4 sm:mb-9">
    <div class="flex-1 text-sm text-n-0">{{ subtitle }}</div>
    <div class="flex-none text-sm text-n-0">
      <span
        :class="{
          'text-r-500': totalModifiers < (modifierGroup.min || 0),
          'text-g-500': totalModifiers >= (modifierGroup.min || 0)
        }"
        >{{ totalModifiers }}</span
      >/{{ modifierGroup.max }}
    </div>
  </div>
  <div class="h-full scrolling-touch overflow-y-scroll min-h-0 shrink">
    <div class="grid md:grid-cols-2 sm:grid-cols-1 gap-4">
      <div
        v-for="modifier in modifierGroup.modifiers"
        :key="modifier.id"
        class="rounded-xl border border-n-600 bg-n-600 flex items-center overflow-hidden gap-2 w-full md:h-16 h-12 cursor-pointer"
        :class="{
          'text-n-200 pointer-events-none opacity-50 cursor-not-allowed':
            isMaxed && multiple && !isSelected(modifier.id),
          '!border-v-300': isSelected(modifier.id)
        }"
        @click="toggle(modifier.id)"
      >
        <div
          class="font-heading font-medium text-base text-n-0 flex justify-center items-center flex-shrink-0 h-full w-12"
          :style="{
            background: modifier.color || '#302F66',
            color: getTextColor(modifier.color)
          }"
        >
          {{ modifier.shortName || shortName(modifier.name) }}
        </div>
        <div
          class="text-n-0 font-body text-base overflow-hidden text-ellipsis whitespace-nowrap"
        >
          {{ modifierName(modifier.name) }}
        </div>
        <div class="ml-auto shrink-0 flex items-center gap-2 pr-2">
          <div v-if="modifier.priceImpact > 0" class="text-sm text-v-300">
            {{ $filters.currency(modifier.priceImpact) }}
          </div>
          <quantity-selector
            v-if="modifierGroup.allowRepeat && isSelected(modifier.id)"
            :quantity="modifiers[modifier.id]"
            :is-maxed="isMaxed"
            @update:quantity="quantity => updateQuantity(modifier.id, quantity)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import QuantitySelector from '../QuantitySelector.vue'
import { computed, defineModel, watch } from 'vue'
import { ModifierGroupType } from '@/types'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const { modifierGroup } = defineProps<{
  modifierGroup: ModifierGroupType
}>()

const modifiers = defineModel<Record<string, number>>('modifiers', {
  required: true
})

const emit = defineEmits(['change', 'update:modifiers'])

function toggle(id: string) {
  if (multiple.value) {
    if (id in modifiers.value) {
      let modifiersCopy: any = {
        ...modifiers.value
      }
      delete modifiersCopy[id]
      emit('update:modifiers', modifiersCopy)
    } else {
      emit('update:modifiers', { ...modifiers.value, [id]: 1 })
    }
  } else {
    emit('update:modifiers', { [id]: 1 })
  }
}

function updateQuantity(id: string, quantity: number) {
  let modifiersCopy = {
    ...modifiers.value
  }
  modifiersCopy[id] = quantity
  emit('update:modifiers', modifiersCopy)
}

function isSelected(id: string) {
  return id in (modifiers.value || {})
}

function shortName(name: string) {
  if (!name) return ''
  let initials = name
    .split(/[.,/ -]/)
    .map(n => n[0])
    .join('')
    .slice(0, 2)
  if (initials.length < 2) {
    return name.slice(0, 2).toUpperCase()
  } else {
    return initials.toUpperCase()
  }
}

function modifierName(name: string) {
  let maxLength = 60
  if (name.length > maxLength) {
    return name.slice(0, maxLength) + '...'
  } else {
    return name
  }
}

function getTextColor(hexcolor?: string) {
  if (!hexcolor) return 'white'
  let color = hexcolor.substr(1, 6)
  var r = parseInt(color.substr(0, 2), 16)
  var g = parseInt(color.substr(2, 2), 16)
  var b = parseInt(color.substr(4, 2), 16)
  var yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 200 ? '#302F66' : 'white'
}

const multiple = computed(() => {
  let min = modifierGroup.min || 0
  let max = modifierGroup.max
  if (!max) return true
  if (min > 1) return true
  return max - min >= 1
})

const subtitle = computed(() => {
  if (!multiple.value) {
    return t('modifier-editor.select-option')
  }
  if (modifierGroup.min && modifierGroup.max) {
    if (modifierGroup.min === modifierGroup.max) {
      return t('modifier-editor.select-exact-option', {
        num: modifierGroup.max
      })
    }
    return t('modifier-editor.select-min-max-option', {
      min: modifierGroup.min,
      max: modifierGroup.max
    })
  }
  if (modifierGroup.min) {
    return t('modifier-editor.select-min-option', {
      min: modifierGroup.min
    })
  }
  if (modifierGroup.max) {
    return t('modifier-editor.select-max-option', {
      max: modifierGroup.max
    })
  }

  return ''
})

const isMaxed = computed(() => {
  return !!modifierGroup.max && totalModifiers.value === modifierGroup.max
})

const totalModifiers = computed(() => {
  if (!modifiers.value) return 0
  return Object.values(modifiers.value).reduce(
    (res: number, quantity: number) => res + quantity,
    0
  )
})

watch(totalModifiers, () => {
  emit('change', [totalModifiers.value, modifierGroup.max])
})
</script>
